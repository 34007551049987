// main.js
$(document).ready(function () {
  function toggleText() {
      $('.toggle-header').on('click', function () {
          const $content = $(this).next('.toggle-content');

          if ($content.is(':visible')) {
              // Close the content
              $content.stop().animate({ height: 0 }, 300, function () {
                  $content.hide(); // Ensure it's hidden after animation
              });
              $(this).removeClass('active');
          } else {
              // Open the content
              $content.show(); // Ensure it's visible before measuring
              const fullHeight = $content.prop('scrollHeight'); // Get the full content height
              $content.css('height', 0).stop().animate({ height: fullHeight }, 300, function () {
                  $content.css('height', 'auto'); // Reset height for flexibility
              });
              $(this).addClass('active');
          }
      });
  }

  toggleText();


  // Spinner functionality
  function spinner() {
    setTimeout(function () {
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.classList.remove("show");
      }
    }, 1);
  }
  spinner();

  // WOW.js initialization
  if (typeof WOW !== "undefined") {
    new WOW().init();
  }

  // Sticky Navbar
  window.addEventListener("scroll", function () {
    const navbar = document.querySelector(".nav-bar");
    if (navbar) {
      if (window.scrollY > 45) {
        navbar.classList.add("sticky-top", "shadow-sm");
        navbar.style.top = "0px";
      } else {
        navbar.classList.remove("sticky-top", "shadow-sm");
        navbar.style.top = "-100px";
      }
    }
  });

  // Back to top button
  window.addEventListener("scroll", function () {
    const backToTop = document.querySelector(".back-to-top");
    if (backToTop) {
      if (window.scrollY > 300) {
        backToTop.style.display = "block";
      } else {
        backToTop.style.display = "none";
      }
    }
  });

  document
    .querySelector(".back-to-top")
    .addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
});
